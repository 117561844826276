.chartjs-custom-tooltip {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e9effa;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  font-family: inherit;
  font-weight: 500;

  .tooltip-title {
    margin-bottom: 6px;

    h6 {
      font-size: 12px;
      font-weight: 500;
      color: #1a1e25;
    }
  }

  .tooltip-body {
    .value {
      margin-right: 16px;
      color: #0d4ca0;
      font-size: 16px;
    }

    .label {
      font-size: 12px;
      color: #6e7581;
    }
  }
}

.chartjs-custom-tooltip.tooltip-bar {
  .tooltip-body {
    .percentage {
      font-size: 12px;
      color: #34a489;
      margin-top: 8px;
    }

    &__bottom {
      border-top: 1px dashed #c3c9d6;
      margin-top: 8px;
      padding-top: 8px;
      // display: flex;
      // flex-wrap: wrap;

      > div {
        // flex: 0 0 50%;
        padding: 2px;
        font-size: 12px;
      }
    }
  }
}
